<template>
	<div>
		<div v-show="previewUrl">
			<img class="preview" :src="previewUrl" :style="{ 'max-width': previewW + 'px' }">
			<b-button type="is-danger" class="delete-button ml-20" icon-right="delete" @click="onDeleteClick" />
		</div>
		<b-field class="file">
			<b-upload v-model="file" @input="onFileChange">
				<a class="button is-success" :class="{ 'is-loading': isLoading }">
					<span>画像を選択</span>
				</a>
			</b-upload>
		</b-field>
	</div>
</template>

<script>

export default {
	name: 'ImagePicker',
	props: {
		previewUrl: {
			type: String,
			default: '',
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		previewW: {
			type: Number,
			default: 100,
		},
	},
	data() {
		return {
			file: null,
		}
	},
	mounted() {
	},
	methods: {
		onFileChange: function() {
			if (!this.file || !this.file.type.match(/^image\/(png|jpeg)$/)) {
				alert('画像ファイルを指定してください');
				return;
			}

			let reader = new FileReader();
			reader.readAsDataURL(this.file);
			reader.onload = (e) => {
				this.$emit('file-change', e.target.result);
			};
		},
		onDeleteClick: function() {
			this.$emit('delete');
		},
	},
}
</script>

<style lang="scss">
@import "@/scss/_vars.scss";

.preview {
	height: auto;
	border: $commonBorder;
}

.delete-button {
	width: 30px;
}
</style>
