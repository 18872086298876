<template>
	<div class="content has-text-grey has-text-centered">
		<template v-if="loading">
		</template>
		<template v-else>
			<p>データはありません</p>
		</template>
	</div>
</template>

<script>

export default {
	name: 'TableEmpty',
	props: {
		loading: {
			type: Boolean,
			default: false,
		}
	},
}
</script>
