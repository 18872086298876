<template>
	<div>{{ `全 ${ totalNum } 件中  ${ pageStartNum }～${ pageEndNum } 件を表示` }}</div>
</template>

<script>

export default {
	name: 'PageInfo',
	props: {
		data: {
			type: Array,
			default: null,
		},
		current: {
			type: Number,
			default: 1,
		},
	},
	data() {
		return {
			totalNum: 0,
			pageStartNum: 0,
			pageEndNum: 0,
		}
	},
	mounted() {
	},
	watch: {
		data: function() {
			this.totalNum = this.data.length;
			this.displayPageInfo()
		},
		current: function() {
			this.displayPageInfo()
		},
	},
	methods: {
		displayPageInfo: function() {
			if (this.totalNum === 0) {
				this.pageEndNum = 0;
				this.pageStartNum = 0;
			} else {
				let max = this.$consts.TABLE_PER_PAGE * this.current;
				this.pageStartNum = max - this.$consts.TABLE_PER_PAGE + 1;
				if (max > this.totalNum) {
					this.pageEndNum = this.totalNum;
				} else {
					this.pageEndNum = this.$consts.TABLE_PER_PAGE * this.current;
				}
			}
		},
	},
}
</script>
