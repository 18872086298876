<template>
	<p class="control has-icons-left has-icons-right">
	<input ref="datePicker" :name="fieldName" class="input" :class="{ 'is-danger': hasError }" v-model="date" type="text" autocomplete="off" @input="onInput">

		<b-icon icon="calendar" class="is-small is-left" custom-size="mdi-24px"></b-icon>

		<span class="icon is-right has-text-danger" v-show="hasError">
			<i class="mdi mdi-alert-circle mdi-24px"></i>
		</span>
	</p>
</template>

<script>
	import flatpickr from "flatpickr";
	import { Japanese } from "flatpickr/dist/l10n/ja.js"

	export default {
		name: "DatePicker",
		props: {
			fieldName: {
				type: String,
				default: 'date',
			},
			dateProvided: {
				type: String,
				default: null,
			},
			hasError: {
				type: Boolean,
				default: false,
			},
			enableTime: {
				type: Boolean,
				default: false,
			},
			allowInput: {
				type: Boolean,
				default: true,
			},
			minDate: {
				default: null,
			},
			maxDate: {
				default: null,
			}
		},
		data() {
			return {
				fp: null,
				date: null,
			}
		},
		mounted() {
			this.$nextTick(() => {
				let dateFormat = 'Y-m-d'
				if (this.enableTime)
					dateFormat += ' h:i K'

				this.fp = flatpickr(this.$refs.datePicker, {
					locale: Japanese,
					enableTime: this.enableTime,
					dateFormat: dateFormat,
					defaultDate: this.dateProvided,
					allowInput: false,
					minDate: this.minDate,
					maxDate: this.maxDate,
				});
			})
		},
		methods: {
			onInput() {
				this.$emit('input', this.date)
			}
		},
		watch: {
			dateProvided (newValue) {
				if (this.fp)
					this.fp.setDate(newValue)
			}
		}
	}
</script>

<style scoped>

</style>
